import findRegistrar from './findRegistrar';
import { Registrar } from './graphql/api/graphql';
import { getPreviousRoute } from './routerState';

const getRegistrarFromRoute = (currentRoute: string) => {
  if (currentRoute === 'register-buyer') {
    return Registrar.MARKETPLACE;
  }

  const previousRoute = getPreviousRoute()?.pathname;
  const routeToCheck = previousRoute || currentRoute;

  return findRegistrar(routeToCheck, Registrar.PWA);
};

export default getRegistrarFromRoute;
