import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ValidationError from '@fuww/library/src/ValidationError';
import { ApolloError } from '@apollo/client';

const serverValidationPropertyTypes = {
  errors: PropTypes.shape({
    graphQLErrors: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string,
      }),
    ),
    message: PropTypes.string,
  }).isRequired,
};

type ServerValidationProperties = Omit<InferProps<
typeof serverValidationPropertyTypes
>, 'errors'> & {
  errors?: ApolloError;
};

const ServerValidation = (
  { errors }: ServerValidationProperties,
) => (
  <>
    { errors?.graphQLErrors.map(({ message: graphQLErrorMessage }) => (
      <ValidationError key={graphQLErrorMessage}>
        {graphQLErrorMessage}
      </ValidationError>
    ))}
    {
      !(
        errors?.graphQLErrors && errors.graphQLErrors.length > 0
      ) && errors?.message && (
        <ValidationError>
          {errors.message}
        </ValidationError>
      )
    }
  </>
);

ServerValidation.propTypes = serverValidationPropertyTypes;

export default ServerValidation;
