import { Registrar } from './graphql/api/graphql';

const routeToRegistrar = {
  lookbook: Registrar.LOOKBOOK,
};

const findRegistrar = (
  route: string,
  fallback: Registrar,
) => {
  const found = Object.entries(routeToRegistrar).find(
    ([path]) => route.includes(path),
  );

  return found ? found[1] : fallback;
};

export default findRegistrar;
