import React from 'react';
import withApollo from '../lib/apollo';
import LoginComponent from '../components/LoginSignup/Login';
import { useSiteContext } from '../components/SiteContext';

export const Login = () => {
  const { dashboard } = useSiteContext();

  return (
    <LoginComponent showBreadcrumbs={!dashboard} />
  );
};

export default withApollo(Login);
