import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../../lib/messages.mjs';

const CookieConsentMessage = () => {
  const intl = useIntl();

  return (
    <p>
      {intl.formatMessage(messages.cookiesStatement)}
      {' '}
      <a
        href="/cookies"
        target="_blank"
        rel="noopener noreferrer"
        tabIndex="-1"
      >
        {intl.formatMessage(messages.cookieDescriptionLinkText)}
      </a>
      .
    </p>
  );
};

export default CookieConsentMessage;
