import { ApolloClient } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';

const onLogin = async (
  data: { login: { accessToken: string } },
  setSubmitDisabled: (value: boolean) => void,
  successMessage: string,
  client: ApolloClient<object>,
  source: string,
) => {
  setSubmitDisabled(false);
  localStorage.setItem('accessToken', (data.login.accessToken));

  window.plausible('Login', {
    props: {
      provider: 'EmailAndPassword',
      source,
    },
  });
  enqueueSnackbar(successMessage, { autoHideDuration: 1500 });

  await client.resetStore();
};

export default onLogin;
