import React, { useEffect, useContext } from 'react';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import { UserContext } from '../UserProvider';
import { Router } from '../../routes.mjs';
import { retrieveDestination, clearRouterState } from '../../lib/routerState';
import { useSiteContext } from '../SiteContext';

const RedirectWhenLoggedIn = () => {
  const { user, loading } = useContext(UserContext);
  const { locale, dashboard } = useSiteContext();

  useEffect(() => {
    if (user) {
      const { route, query } = retrieveDestination(
        dashboard ? 'dashboard' : 'index',
        {},
        locale,
      );

      clearRouterState();
      Router.replaceRoute(route, query);
    }
  }, [user, locale, dashboard]);

  return loading ? <HiddenLoader /> : null;
};

export default RedirectWhenLoggedIn;
