import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';

const MessageWrapper = styled.span`
    font-family: var(--font-primary);
    height: auto;
    color: #ee0000;
    line-height: 1.2em;
    font-weight: bold;
    font-size: 12px;
    display: inline-block;
    margin: 8px 4px;
    max-width: 100%;
    overflow: hidden;
  `;

export const WarningMessage = styled(MessageWrapper)`
  display: block;
  color: #ffa500;

  &&, && a {
    font-size: 12px;
  }
`;

const validationErrorPropertyTypes = {
  children: PropTypes.node,
  errors: PropTypes.shape({}),
  name: PropTypes.string,
};

type ValidationErrorProperties = InferProps<
typeof validationErrorPropertyTypes
>;

const ValidationError = (
  { children, name, errors }: ValidationErrorProperties,
) => (
  <MessageWrapper className="error">
    {errors && name && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => message}
      />
    )}
    {children}
  </MessageWrapper>
);

ValidationError.propTypes = validationErrorPropertyTypes;

export default ValidationError;
