import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import ButtonWithIcon, { SocialLoginButtonContainer }
from '@fuww/library/src/Login/ButtonWithIcon';
import hiddenLoading from '../../lib/hiddenLoading';
import messages from '../../lib/messages.mjs';
import { useSiteContext } from '../SiteContext';
import { Registrar } from '../../lib/graphql/api/graphql';
import getRegistrarFromRoute from '../../lib/getRegistrarFromRoute';

const defaultGetAccessToken = (
  { data: { access_token: accessToken } },
) => accessToken;

export const providers = {
  google: {
    iconUrl:
    'https://media.fashionunited.com/logos/google__1_.HubF6pFQ92pELpfAC.svg',
    label: 'Google',
    providerArgument: 'GOOGLE',
    Component: dynamic(
      () => import('reactjs-social-login')
        .then((module) => module.LoginSocialGoogle), {
        ssr: false,
        loading: hiddenLoading,
      },
    ),
    properties: {
      // eslint-disable-next-line max-len
      client_id: '113803436273-2ejsbcb6b3ql3apabqv71rtuj5s4r01m.apps.googleusercontent.com',
      scope: 'openid profile email',
      discoveryDocs: 'claims_supported',
    },
    getAccessToken: defaultGetAccessToken,
  },
  linkedin: {
    iconUrl:
    'https://media.fashionunited.com/media/logos/LinkedIn-Bug.svg',
    label: 'LinkedIn',
    providerArgument: 'LINKEDIN',
    Component: dynamic(
      () => import('reactjs-social-login')
        .then((module) => module.LoginSocialLinkedin), {
        ssr: false,
        loading: hiddenLoading,
      },
    ),
    properties: {
      client_id: '78nv4on1akw07d',
      client_secret: 'WPL_AP1.PCfQXHA90lIhIDsh.8CMrMQ==',
      scope: 'openid profile email',
    },
    getAccessToken: defaultGetAccessToken,
  },
};

const routeToRegistrar = {
  article: Registrar.NEWS,
  lookbook: Registrar.LOOKBOOK,
  dashboard: Registrar.DASHBOARD,
};

export function LoginButton({
  provider,
  onLogin,
  onLoginSuccess,
  setErrorMessage,
  setDisabled,
  disabled,
  labelKey,
  ...rest
}) {
  const intl = useIntl();
  const { url } = useSiteContext();
  const { route } = useRouter();

  const {
    Component,
    properties,
    iconUrl,
    label,
    getAccessToken,
    providerArgument,
  } = providers[provider];

  return (
    // workaround for disabling is not supported by reactjs-social-login
    <SocialLoginButtonContainer disabled={disabled}>
      <Component
        onLoginStart={onLogin}
        onResolve={useCallback(
          (response) => onLoginSuccess({
            accessToken: getAccessToken(response),
            providerArgument,
            registeredVia: getRegistrarFromRoute(route, routeToRegistrar),
          }),
          [onLoginSuccess, getAccessToken, providerArgument, route],
        )}
        onReject={useCallback(({ data: error }) => {
          setErrorMessage(error.message);
          setDisabled(false);
        }, [setErrorMessage, setDisabled])}
        isOnlyGetToken
        redirect_uri={url}
        {...properties}
      >
        <ButtonWithIcon
          icon={iconUrl}
          alt={label}
          disabled={disabled}
          className={`social-login-${provider}`}
          text={
            intl.formatMessage(messages[labelKey], {
              provider: label,
            })
          }
          {...rest}
        />
      </Component>
    </SocialLoginButtonContainer>
  );
}

LoginButton.propTypes = {
  provider: PropTypes.oneOf(
    Object.keys(providers),
  ).isRequired,
  labelKey: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setDisabled: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

LoginButton.defaultProps = {
  disabled: false,
  labelKey: 'login.logInWithProvider',
};

export default LoginButton;
